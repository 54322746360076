import React, { FC } from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import NewsletterForm from "./NewsletterForm";
import commonLinks from "../constants";

import "./Footer.scss";

type FooterProps = {
  includeForm?: boolean;
  children?: React.ReactNode;
  includeYellow?: boolean;
};

export const navItems = [
  commonLinks.team,
  commonLinks.careers,
  {
    link: "https://ideas42.org",
    displayText: "ideas42",
    props: { target: "_blank", rel: "noreferrer" },
  },
];

const Footer: FC<FooterProps> = ({ includeForm, children, includeYellow }) => (
  <footer
    id="footer"
    className={classNames("contentinfo", {
      "contentinfo--yellow-polygon": includeYellow,
    })}
  >
    {children}
    <div className="contentinfo__contents standard-restricted-width">
      {includeForm && <NewsletterForm />}
      <nav className="contentinfo__nav" aria-label="Footer">
        <menu>
          {navItems.map(({ link, displayText, props }: NavItem) => (
            <li key={displayText}>
              {props ? (
                <a href={link} key={link} {...props}>
                  {displayText}
                </a>
              ) : (
                <Link to={link} key={link}>
                  {displayText}
                </Link>
              )}
            </li>
          ))}
        </menu>
      </nav>
    </div>
  </footer>
);

Footer.defaultProps = {
  includeForm: true,
  includeYellow: true,
  children: null,
};

export default Footer;
