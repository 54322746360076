const commonLinks = {
  studio: {
    displayText: "Studio",
    link: "/studio",
  },
  team: {
    displayText: "Team",
    link: "/team",
  },
  careers: {
    displayText: "Careers",
    link: "/careers",
  },
  contact: {
    displayText: "Contact",
    link: "/contact",
  },
};

export default commonLinks;
