import React from "react";
import "./Breadcrumbs.scss";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

interface Breadcrumb {
  text: string;
  pathName: string;
  isLastCrumb?: boolean;
  pageTitle?: string;
}

function Breadcrumb({ text, pathName, isLastCrumb, pageTitle }: Breadcrumb) {
  return (
    <li className="breadcrumb__item">
      {isLastCrumb && <strong>{pageTitle || text}</strong>}
      {!isLastCrumb && (
        <Link to={`/${pathName}`}>
          <strong>{text}</strong>{" "}
        </Link>
      )}
    </li>
  );
}

function pathToPageTitle(path: string) {
  return path
    .split("-")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");
}

function Breadcrumbs({
  pageTitle,
}: {
  pageTitle?: string;
}): React.ReactElement {
  const { pathname } = useLocation();
  const paths = pathname
    .split("/")
    .filter(Boolean)
    .map((pathName) => ({
      pathName,
      text: pathToPageTitle(pathName),
    }));

  return (
    <ul className="breadcrumbs grid--max-3">
      {paths.map(({ pathName, text }, index) => (
        <Breadcrumb
          text={text}
          key={text}
          pathName={pathName}
          isLastCrumb={index === paths.length - 1}
          pageTitle={pageTitle}
        />
      ))}
    </ul>
  );
}

export default Breadcrumbs;
