import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import { useFirstRender } from "../hooks";
import Footer from "./Footer";
import Cta from "./Cta";
import MainNav from "./MainNav";

import "../styles/index.scss";
import "./Layout.scss";

type LayoutProps = {
  children: React.ReactNode;
  includeFooterForm?: boolean;
  footerChildren?: React.ReactNode;
  includeYellowOnFooter?: boolean;
  className?: string;
};

const DefaultFooterText = (
  <Cta
    subheader="Current status: Closed"
    header="Applications are closed"
    tag="Ventures for Shared Prosperity"
    includeButton={false}
  >
    <p className="font-size-3 g-max-3-half g-centered">
      Sign up for our mailing list below to get a notification when applications
      open for our next cohort.
    </p>
  </Cta>
);

const Layout: React.FC<LayoutProps> = ({
  children,
  includeFooterForm,
  footerChildren,
  includeYellowOnFooter,
  className,
}: LayoutProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Ref will be used to set focus when the menu is closed
  const contentRef = useRef<HTMLDivElement | null>(null);
  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (!isFirstRender && !isMobileMenuOpen && contentRef.current) {
      contentRef.current.focus();
    }
  }, [isMobileMenuOpen]);

  const metaTitle = "ideas42 Venture Studio";
  const metaImage =
    "https://ideas42ventures.com/assets/images/social-default.png";
  const metaDescription =
    "We build companies to solve massive social challenges";
  const metaImageAlt =
    "A photo illustration made up of squares, circles, and triangles to represent the three building blocks of ideas42 Ventures";

  return (
    <>
      <Helmet>
        {/* React Helmet replaces title when found in child components */}
        <title>ideas42 Venture Studio</title>
        <meta property="og:site_name" content={metaTitle} />

        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />

        <meta property="og:image" content={metaImage} />
        <meta property="og:image:alt" content={metaImageAlt} />

        <meta name="twitter:image" content={metaImage} />
        <meta name="twitter:image:alt" content={metaImageAlt} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ideas42ventures" />
        <meta property="og:type" content="website" />
      </Helmet>
      <MainNav
        isMenuOpen={isMobileMenuOpen}
        setIsMenuOpen={setIsMobileMenuOpen}
      />
      <div
        className={classNames("content cy-content", className, {
          "content--hidden": isMobileMenuOpen,
        })}
        tabIndex={-1}
        ref={contentRef}
      >
        <main className="g-section-vertical">{children}</main>
        <Footer
          includeForm={includeFooterForm}
          includeYellow={includeYellowOnFooter}
        >
          {footerChildren}
        </Footer>
      </div>
    </>
  );
};

Layout.defaultProps = {
  includeFooterForm: true,
  footerChildren: DefaultFooterText,
  includeYellowOnFooter: true,
};

export default Layout;
