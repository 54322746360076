import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import React, { ReactNode } from "react";
import Breadcrumbs from "./Breadcrumbs";

import "./Hero.scss";

interface HeroProps {
  title: string;
  body?: ReactNode;
  tag?: string;
  tagImage?: string;
  titleClass?: string;
  bodyClass?: string;
  className?: string;
  children?: ReactNode;
  showBreadcrumbs?: boolean;
  sidebar?: ReactNode;
  pageTitle?: string;
}

const Hero: React.FC<HeroProps> = ({
  title,
  body,
  tag,
  tagImage,
  titleClass,
  bodyClass,
  className,
  children,
  showBreadcrumbs,
  sidebar,
  pageTitle = "",
}) => (
  <section
    className={classNames("hero", "standard-restricted-width", className)}
  >
    <div className="grid--max-3">
      {showBreadcrumbs && <Breadcrumbs pageTitle={pageTitle} />}
      {tagImage && (
        <div>
          <StaticImage
            className="hero__tag"
            src="../images/cohort-mark.svg"
            alt=""
          />
        </div>
      )}
      <h1 className={classNames("hero__title", titleClass)}>
        {tag && <small className="hero__tag">{tag}</small>}
        {title}
      </h1>
      {sidebar && body && (
        <div className="hero__columns">
          <p className={classNames("hero__body", bodyClass)}>{body}</p>
          <div className="hero__sidebar">{sidebar}</div>
        </div>
      )}
      {!sidebar && body && (
        <p className={classNames("hero__body", "grid--max-3", bodyClass)}>
          {body}
        </p>
      )}
    </div>
    {children}
  </section>
);

Hero.defaultProps = {
  tag: null,
};

export default Hero;
