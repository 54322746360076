import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import I42Logo from "../images/ideas42ventures-logo-color.inline.svg";
import I42WhiteLogo from "../images/i42ventures-logo-monochrome-white.svg";
import MenuIcon from "../images/menu-icon.inline.svg";
import CloseIcon from "../images/close-menu-icon.inline.svg";
import commonLinks from "../constants";

import "./MainNav.scss";

export const navItems: NavItem[] = [
  commonLinks.studio,
  commonLinks.team,
  commonLinks.careers,
  commonLinks.contact,
];

export const testIds = {
  whiteLogo: "white-logo",
  colorLogo: "color-logo",
  closeButton: "close-button",
  openButton: "open-button",
  mobileFocused: "focus-on-me",
};

type NavProps = {
  className: string;
  itemClass?: string;
};

const Nav: React.FC<NavProps> = ({ className, itemClass }) => (
  <nav className={className} aria-label="main">
    {navItems.map(({ link, displayText }) => (
      <Link
        to={link}
        key={link}
        className={classNames("banner__nav-item", itemClass)}
      >
        {displayText}
      </Link>
    ))}
  </nav>
);

Nav.defaultProps = {
  itemClass: null,
};

interface MobileNavProps {
  className?: string;
  setIsMenuOpen: (a: boolean) => void;
}

export const MobileNav: React.FC<MobileNavProps> = ({
  className,
  setIsMenuOpen,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  // focus on the element when it enters the DOM
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <div
      className={classNames("banner__mobile g-padded", className)}
      id="banner__mobile"
      tabIndex={-1}
      ref={ref}
    >
      <div
        className="banner__mobile-header"
        data-testid={testIds.mobileFocused}
      >
        <a
          href="/"
          className="banner__logo"
          aria-label="Go to ideas42 Venture Studio home"
          data-testid={testIds.whiteLogo}
        >
          <img src={I42WhiteLogo} alt="" />
        </a>

        <button
          data-testid={testIds.closeButton}
          className="banner-mobile__menu-button cy-mobile-close-nav"
          onClick={() => setIsMenuOpen(false)}
          aria-label="Close Menu"
          type="button"
        >
          <CloseIcon />
        </button>
      </div>
      <Nav className="banner__mobile-nav" itemClass="banner__mobile-nav-item" />
    </div>
  );
};

MobileNav.defaultProps = {
  className: null,
};

interface MainNavProps {
  className?: string;
  isMenuOpen: boolean;

  setIsMenuOpen: (a: boolean) => void;
}

const MainNav: React.FC<MainNavProps> = ({
  className,
  isMenuOpen,
  setIsMenuOpen,
}) => {
  if (isMenuOpen) {
    return <MobileNav setIsMenuOpen={setIsMenuOpen} />;
  }

  return (
    <>
      <header className={classNames("banner g-padded", className)}>
        <div className="banner__contents">
          <Link
            to="/"
            className="banner__logo"
            aria-label="Go to ideas42 Ventures Studio home"
          >
            <I42Logo data-testid={testIds.colorLogo} alt="" />
          </Link>
          <Nav className="banner__nav" />
          <button
            data-testid={testIds.openButton}
            className="banner__menu-button cy-mobile-open-nav"
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open Menu"
            type="button"
          >
            <MenuIcon />
          </button>
        </div>
      </header>
    </>
  );
};

MainNav.defaultProps = {
  className: null,
};

export default MainNav;
