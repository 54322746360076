import React, { useState } from "react";
import "./NewsletterForm.scss";

export const newsletterTestId = "newsletter-form-id";
const emailInputId = "mce-EMAIL-footer";

const NewsletterForm: React.FC = () => {
  const [email, setEmail] = useState("");
  return (
    // <!-- Begin Mailchimp Signup Form -->
    <div id="mc_embed_footer_signup" data-testid={newsletterTestId}>
      <form
        className="newsletter-form g-max-2 validate"
        action="https://ideas42ventures.us1.list-manage.com/subscribe/post?u=f28b0f7c2bec71e9dc4a14f7f&amp;id=69350d4819&amp;SIGNUP=footer"
        method="post"
        id="mc-embedded-subscribe-footer-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_footer_signup_scroll">
          <fieldset className="mc-field-group field-group">
            <legend>Get updates</legend>
            <p className="newsletter-info g-max-1-half">
              We share brief updates about our process and progress.{" "}
              <strong>Never spam</strong>.
            </p>

            <div className="mce-field-group field--connected-btn">
              {/* Not sure why we're getting this linter error, the input is labeled. */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor={emailInputId}>Email Address </label>
              <input
                id={emailInputId}
                type="email"
                value={email}
                name="EMAIL"
                className="required email input"
                placeholder="name@example.com"
                autoComplete="off"
                autoCapitalize="off"
                required
                onChange={(e) => setEmail(e.target.value)}
                data-testid="email-input"
              />
              <div id="mce-footer-responses" className="clear">
                <div
                  className="response hidden"
                  id="mce-error-footer-response"
                />
                <div
                  className="response hidden"
                  id="mce-success-footer-response"
                />
              </div>
              {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_f28b0f7c2bec71e9dc4a14f7f_69350d4819"
                  tabIndex={-1}
                  defaultValue=""
                />
              </div>
              <input
                id="mc-embedded-footer-subscribe"
                type="submit"
                value="Subscribe"
                name="subscribe"
                className="button btn"
              />
            </div>
          </fieldset>
        </div>
      </form>
    </div>
    //  <!--End mc_embed_signup-->
  );
};

export default NewsletterForm;
