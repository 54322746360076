import * as React from "react";
import classNames from "classnames";
import Arrow from "../images/graphic-arrow-btn.inline.svg";

import "./Cta.scss";

type CtaProps = {
  subheader?: string;
  header: React.ReactNode;
  children: React.ReactNode;
  tag?: string;
  buttonText?: string;
  className?: string;
  includeButton?: boolean;
};

const Cta: React.FC<CtaProps> = ({
  subheader,
  header,
  tag,
  children,
  buttonText,
  className,
  includeButton = true,
}) => (
  <div className={classNames("cta", className)}>
    <div className="cta__contents standard-restricted-width">
      {tag && <div className="cta__tag">{tag}</div>}
      <h2>{header}</h2>
      {subheader && <p className="font-size-4">{subheader}</p>}
      {children}
      {includeButton && (
        <a
          href="/ventures-for-shared-prosperity/"
          className="btn btn--outlined cta__btn btn--arrow"
          aria-label="Navigate to the Ventures for Shared Prosperity page for more info"
        >
          {buttonText}{" "}
          <span className="btn__arrow">
            <Arrow alt="" />
          </span>
        </a>
      )}
    </div>
    <div className="cta__background" />
  </div>
);

Cta.defaultProps = {
  buttonText: "Follow along",
};

export default Cta;
